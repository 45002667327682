<template>
    <div>
        <form v-if="!successful" @submit.prevent="onSubmit" novalidate>
            <div class="form-group" :class="{'has-error': hasError('name')}">
                <label class="control-label" for="contact-form-name">Your name*</label>
                <input id="contact-form-name"
                       type="text"
                       name="name"
                       class="form-control"
                       v-model="form.name" required>
                <small class="help-block m-0" v-text="getError('name')"></small>
            </div>

            <div class="form-group" :class="{'has-error': hasError('organisation')}">
                <label class="control-label" for="contact-form-organisation">Your organisation</label>
                <input id="contact-form-organisation"
                       type="text"
                       name="organisation"
                       class="form-control"
                       v-model="form.organisation">
                <small class="help-block m-0" v-text="getError('organisation')"></small>
            </div>

            <div class="form-group" :class="{'has-error': hasError('email')}">
                <label class="control-label" for="contact-form-email">Your email*</label>
                <input id="contact-form-email"
                       type="email"
                       name="email"
                       class="form-control"
                       v-model="form.email" required>
                <small class="help-block m-0" v-text="getError('email')"></small>
            </div>

            <div class="form-group" :class="{'has-error': hasError('phone')}">
                <label class="control-label" for="contact-form-phone">
                    Your contact number{{ form.preference === 'phone' ? '*' : '' }}
                </label>
                <input id="contact-form-phone"
                       type="text"
                       name="phone"
                       class="form-control"
                       v-model="form.phone" :required="form.preference === 'phone'">
                <small class="help-block m-0" v-text="getError('phone')"></small>
            </div>

            <div class="form-group" :class="{'has-error': hasError('preference')}">

                <p class="control-label font-bold">Prefer a response by email or a call?</p>

                <div class="radio">
                    <label>
                        <input type="radio" name="preference" value="email" v-model="form.preference">
                        Email
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio" name="preference" value="phone" v-model="form.preference">
                        Phone
                    </label>
                </div>

                <small class="help-block m-0" v-text="getError('preference')"></small>
            </div>

            <div class="form-group" :class="{'has-error': hasError('subject')}">
                <label class="control-label" for="contact-form-subject">Your subject</label>
                <input id="contact-form-subject"
                       type="text"
                       name="subject"
                       class="form-control"
                       v-model="form.subject" required>
                <small class="help-block m-0" v-text="getError('subject')"></small>
            </div>

            <div class="form-group" :class="{'has-error': hasError('message')}">
                <label class="control-label" for="contact-form-message">Your message*</label>
                <textarea id="contact-form-message"
                          name="message"
                          class="form-control"
                          rows="5"
                          v-model="form.message" required></textarea>
                <small class="help-block m-0" v-text="getError('message')"></small>
            </div>

            <vue-recaptcha class="mb-4" sitekey="6LfQJF4kAAAAAEiDbHk7xu1pFYLHTlOFUCRZaRxZ"
                           @verify="onCaptchaVerified"
                           @expired="onCaptchaExpired">

            </vue-recaptcha>

<!--            <vue-recaptcha class="mb-4" sitekey={{process.env.RECAPTCHA_SITE}}-->
<!--                           @verify="onCaptchaVerified"-->
<!--                           @expired="onCaptchaExpired">-->

<!--            </vue-recaptcha>-->




            <button type="submit" class="btn btn-lg btn-outline btn-outline-purple">
                <span v-if="!processing">Submit</span>
                <i v-if="processing" class="fa fa-spinner fa-spin fa-fw"></i>
            </button>

        </form>
        <div v-else class="contact-form-response">
            <h3 class="text-center">Thanks, we'll contact you shortly.</h3>
        </div>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
export default {
    components: { VueRecaptcha },

    props: ['recaptcha'],

    data() {
        return {
            form: {
                name: '',
                organisation: '',
                email: '',
                phone: '',
                preference: '',
                subject: '',
                message: '',
                recaptcha: '',
            },
            errors: {},
            processing: false,
            successful: false,
            disabled: true,
            site_key: this.recaptcha,
        }
    },

    mounted() {
        for (let input in this.form) {
            if (this.form.hasOwnProperty(input)) {
                this.$watch('form.' + input, () => this.clearError(input));
            }
        }
    },

    methods: {

        onCaptchaVerified: function (recaptchaToken) {
            this.form.recaptcha = recaptchaToken
            this.validateCaptcha = true
        },
        onCaptchaExpired: function () {
            this.$refs.recaptcha.reset();
        },

        onSubmit() {

            if (this.processing) {
                return;
            }

            this.processing = true;

            axios.post('/contact', this.form)
                .then(response => {
                    this.successful = true;
                })
                .catch(({response}) => {
                    if (response.status === 422) {
                        this.errors = Object.assign({}, response.data.errors);
                    }
                })
                .then(response => this.processing = false);
        },
        hasError(input) {
            return this.errors.hasOwnProperty(input);
        },
        getError(input) {
            if (this.hasError(input)) {
                return this.errors[input][0];
            }

        },
        clearError(input) {
            if (!input) {
                this.errors = {};

                return;
            }

            let errors = Object.assign({}, this.errors);

            Object.keys(errors)
                .filter(e => e === input || e.startsWith(`${input}.`) || e.startsWith(`${input}[`))
                .forEach(e => delete errors[e]);

            this.errors = errors;
        }
    },

    watch: {
        'form.preference'(value) {
            if (value === 'email') {
                this.clearError('phone');
            }
        }
    }
};

</script>
