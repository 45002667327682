require('./bootstrap')

import { createApp } from 'vue'
import ContactForm from "./components/ContactForm";

const app = createApp({})

app.component('contact-form', ContactForm)

app.mount('#app')
